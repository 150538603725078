.fields {
  display: flex;
  p {
    margin: 0;
  }
}

.checkbox{
  margin-top: 10px;
}
