.multicolumn_table {
    height: 100%;
    border: 1px solid var(--pro-outline-border);
    border-radius: 3px;
    padding: 1rem;
    &:not(.pro_table_wrapper) {
      table {
        width: 100%;
        tr {
          td {
            width: 50%;
            padding: 5px 0;
            & + td {
              padding-left: 5px;
            }
          }
        }
      }
    }
    &.pro_table_wrapper {
      table th {
        white-space: initial;
      }
    }
  }
  .multicolumn_wrapper {
    --pro-gutter-y: 10px;
    ~ .multicolumn_wrapper {
      margin-top: 0px;
    }
  }
  
  .card_main_wrapper {
    height: calc(100vh - 154px);
    .card_flow_wrap {
      height: 100%;
      overflow-x: auto;
      > div{
        min-width: 210px;
      }
    }
  }
  
  .table_main_wrapper {
    height: calc(100vh - 134px);
    position: relative;
    overflow-y: auto;
    border-left: 1px solid var(--pro-outline-border);
    .table_flow_wrapper {
      overflow-y: auto;
      height: 100%;
      padding-right: 5px;
      padding-left: 10px;
      padding-bottom: 4.4rem;
    }
  }
  .footer_btn_wrap {
    position: absolute;
    bottom: 0;
    background-color: var(--pro-light);
    z-index: 5;
    border-top: 1px solid var(--pro-outline-border);
    width: calc(100% + 10px);
    right: -10px;
  }
  
  .filter_btn {
    height: 100%;
    align-items: center;
  }
  
  .compare_wrap {
    height: calc(100vh - 60px);
    overflow: hidden;
    overflow-y: auto;
    border-left: 1px solid var(--pro-outline-border);
    .compare_inner_wrap {
      padding-left: 1rem;
      height: 100%;
    }
  }
  .ftr_btn_wrap {
    position: sticky;
    margin-top: auto;
    background-color: var(--pro-light);
    z-index: 5;
    bottom: 0;
    border-top: 1px solid var(--pro-outline-border);
  }

  .disabled_container{
    background-color: rgb(238, 238, 238);
    padding: 10px ;
  }
  