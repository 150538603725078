.multicolumn_table {
  height: 100%;
  border: 1px solid var(--pro-outline-border);
  border-radius: 3px;
  padding: 1rem;
  &:not(.pro_table_wrapper) {
    table {
      width: 100%;
      tr {
        td {
          width: 50%;
          padding: 5px 0;
          & + td {
            padding-left: 5px;
          }
        }
      }
    }
  }
  &.pro_table_wrapper {
    table th {
      white-space: initial;
    }
  }
}
.multicolumn_wrapper {
  --pro-gutter-y: 10px;
  ~ .multicolumn_wrapper {
    margin-top: 0px;
  }
}

.card_main_wrapper {
  height: calc(100vh - 154px);
  .card_flow_wrap {
    height: 100%;
    overflow-x: auto;
    > div {
     min-width: 210px;
    }
  }
}

.table_main_wrapper {
  height: calc(100vh - 154px);
  position: relative;
  border-left: 1px solid var(--pro-outline-border);
  overflow-y: auto;
  .table_flow_wrapper {
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
    padding-left: 10px;
    padding-bottom: 4.4rem;
  }
}
.footer_btn_wrap {
  position: absolute;
  bottom: 0;
  background-color: var(--pro-light);
  z-index: 5;
  border-top: 1px solid var(--pro-outline-border);
  width: calc(100% + 10px);
  right: -10px;
}

.filter_btn {
  height: 100%;
  align-items: center;
}

.content_box {
  p + p {
    margin-top: 5px;
  }
}
.cvd_list {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 0 !important;
  > h6 {
    padding-inline: 11px;
    margin-bottom: 8px !important;
  }
  > div {
    padding-inline: 11px;
    overflow-y: auto;
    height: 100%;
  }
}

.prints {
  position: fixed;
  width: 0;
  height: 0;
  visibility: hidden;
}

.diamond_img_wrap{
  border: 1px solid var(--pro-outline-border);
  border-radius: 6px;
  padding: 20px;
  max-width: 200px;
  img{
    object-fit: contain;
  }
}