.filter_btn {
  height: 100%;
  align-items: center;
}
.pdf_container {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}
