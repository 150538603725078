.pdf_container {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}

.footer_btn_wrap {
  border-top: 1px solid var(--pro-outline-border);
}

.columns {
  display: flex;
  justify-content: space-between;
}
.container {
  background-color: var(--pro-secondary);
  border-radius: 5px;
  padding: 1rem 10px 4px;
  margin: 15px 0;
  position: relative;
}
.head {
  margin-bottom: 35px;
}
.courier {
  margin-top: 15px;
}
