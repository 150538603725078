.root {
    width: 100%;
    position: relative;
    background-color: var(--pro-light);
  }
  .root_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .root_progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .root_progress_inner {
    width: auto;
    margin: 0px -4px;
    display: flex;
    flex-wrap: wrap;
  }
  .root_items {
    width: 25%;
    display: flex;
    padding: 0px 4px;
  }
  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid var(--pro-secondary);
    border-radius: 3px;
    cursor: pointer;
    transition: .3s border-color ease-in-out;
    &.active {
      border-color: var(--pro-dark) !important;
    }
    &:hover {
      border-color: var(--pro-secondary-hover);
    }
  }
  .item_progress_container {
    width: 100%;
    height: 5px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    background-color: var(--pro-secondary);
  }
  .item_progress_bar {
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    background-color: var(--pro-dark);
    display: flex;
    align-items: flex-start;
  }