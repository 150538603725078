.root {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--pro-outline-border);
    border-radius: 4px;
    padding: 5px 10px;
    & + .container {
      margin-top: 12px;
    }
    .icon {
      max-width: none;
      font-size: 20px !important;
    }
  }
}

.main_container {
  &_inner {
    width: 100%;
  }
  :global {
    .nav {
      // padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 10px;
      margin-bottom: 10px;
      flex-wrap: nowrap;
      background-color: var(--pro-light);
      z-index: 9;
      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;
        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;
          &:not(.active) {
            border: 1px solid transparent;
            border-bottom: 0;
          }
          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            &::after {
              background-color: var(--pro-primary);
            }
          }
          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}


.pt_24{
  padding-top: 24px;
}