.root {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid var(--pro-outline-active);
  --icon-scale: 1;
  cursor: pointer;
  .img_root {
    width: 100%;
  }
  .delete_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(var(--icon-scale));
    z-index: 2;
    background-color: var(--pro-primary);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    will-change: transform;
    transition: transform 0.25s ease-in;
    .delete_icon {
      font-size: 12px !important;
    }
  }
  &:hover {
    --icon-scale: 1.2;
  }
}
.wrapper {
  > label {
    color: var(--pro-input-text);
  }
  .upload_wrap {
    border: 1px dashed var(--pro-outline-border);
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
  }
}
.upload_box {
  border: 1px dashed var(--pro-outline-border);
  // max-width: 74px;
  width: 100%;
  border-radius: 4px;
  transition: background-color 0.25s ease-in;
  position: relative;
  &.error{
    border-color: var(--pro-danger);
  }
  &:hover {
    background-color: var(--pro-disable-bg);
  }
  .add_text {
    padding-top: 2px;
    line-height: 1.2;
    color: var(--pro-input-text);
    font-size: 11px;
  }
  .file {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}


.error{
  color: var(--pro-danger);
  margin-top: 5px;
  font-size: 12px;
  display: inline-block;
}