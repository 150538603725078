// Light theme color variables
$pro-primary-light: #1394e5;
$pro-primary-text-light: #ffffff;
$pro-primary-hover-light: #1585cb;
$pro-primary-active-light: #1394e5;
$pro-secondary-light: #f4f5f7;
$pro-secondary-hover-light: #f0f1f3;
$pro-secondary-active-light: #ebecf0;
$pro-success-light: #117872;
$pro-danger-light: #b32318;
$pro-light-light: #fff;
$pro-dark-light: #000;
$pro-outline-light: #fff;
$pro-outline-hover-light: #f8f9fb;
$pro-outline-active-light: #f4f5f7;
$pro-outline-border-light: #dfe3eb;
$pro-disable-text-light: #a5adba;
$pro-disable-bg-light: #f5f6f7;
$pro-input-text-light: #4c4c4c;
$pro-input-hover-bg-light: #fafbfc;
$pro-input-focus-shadow-light: #1394e5;
$pro-input-active-light: #7a869a;
$pro-avatar-remaining-bg-light: #dfe1e6;
$pro-nav-btn-link-hover-light: #edf5fd;
$pro-check-icon-color-light: #67bff5;
$pro-range-bg-light: #dbdee3;
$pro-image-gray-overlay-light: #ececec;
$pro-invoice-label-light: #676767;
$pro-input-option-hover-bg-light: #ecfeff;
$pro-input-option-active-bg-light: #ecfeff;
$pro-staff-card-bg-light: #fcfcfc;
$pro-green-light: #00B232;
$pro-pagination-shadow-light : rgba(0, 0, 0, 0.1);
//shimmers
$pro-shimmer-bg-light: #f8f6f6;
$pro-shimmer-gradient-color1-light: #f6f7f8;
$pro-shimmer-gradient-color2-light: #edeef1;
$pro-shimmer-gradient-color3-light: #f6f7f8;
$pro-shimmer-gradient-color4-light: #f6f7f8;

// Dark theme color variables
$pro-primary-dark: #b1007b;
$pro-primary-text-dark: #ffffff;
$pro-primary-hover-dark: #821561;
$pro-primary-active-dark: #610044;
$pro-secondary-dark: #4e4e4e;
$pro-secondary-hover-dark: #727272;
$pro-secondary-active-dark: #393939;
$pro-success-dark: #117872;
$pro-danger-dark: #c63d33;
$pro-light-dark: #1c1c1c;
$pro-dark-dark: #fff;
$pro-outline-dark: #000;
$pro-outline-hover-dark: #727272;
$pro-outline-active-dark: #393939;
$pro-outline-border-dark: #404040;
$pro-disable-text-dark: #a5adba;
$pro-disable-bg-dark: #f5f6f7;
$pro-input-text-dark: #ebeaea;
$pro-input-hover-bg-dark: #2a2a2a;
$pro-input-focus-shadow-dark: #660047a6;
$pro-input-active-dark: #7a869a;
$pro-avatar-remaining-bg-dark: #dfe1e6;
$pro-nav-btn-link-hover-dark: #323232;
$pro-check-icon-color-dark: #a11d2b;
$pro-range-bg-dark: #dbdee3;
$pro-image-gray-overlay-dark: #ececec;
$pro-invoice-label-dark: #676767;
$pro-input-option-hover-bg-dark: #660047a6;
$pro-input-option-active-bg-dark: #660047a6;
$pro-staff-card-bg-dark: #fcfcfc;
$pro-green-dark :#00B232;
$pro-pagination-shadow-dark : rgba(255, 255, 255, 0.1);
//shimmers
$pro-shimmer-bg-dark: #5d5d5d;
$pro-shimmer-gradient-color1-dark: #575757;
$pro-shimmer-gradient-color2-dark: #7c7c7c;
$pro-shimmer-gradient-color3-dark: #414141;
$pro-shimmer-gradient-color4-dark: #5d5d5d;
$pro-nav-link-active-color-dark: #ffffff;

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 16px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
