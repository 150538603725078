.multicolumn_table {
  height: 100%;
  border: 1px solid var(--pro-outline-border);
  border-radius: 3px;
  padding: 1rem;
  &:not(.pro_table_wrapper) {
    table {
      width: 100%;
      tr {
        td {
          width: 50%;
          padding: 5px 0;
          vertical-align: top;
          & + td {
            padding-left: 5px;
          }
        }
      }
    }
  }
  &.pro_table_wrapper {
    table th {
      white-space: initial;
    }
  }
}
.multicolumn_wrapper {
  --pro-gutter-y: 10px;
  ~ .multicolumn_wrapper {
    margin-top: 0px;
  }
}

.card_main_wrapper {
  height: calc(100vh - 154px);
  .card_flow_wrap {
    height: 100%;
    overflow-x: auto;
    >div{
        min-width: 210px;
    }
  }
}

.table_main_wrapper {
  height: calc(100vh - 134px);
  position: relative;
  border-left: 1px solid var(--pro-outline-border);
  overflow-y: auto;
  .table_flow_wrapper {
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
  }
}
.footer_btn_wrap {
  background-color: var(--pro-light);
  z-index: 5;
  border-top: 1px solid var(--pro-outline-border);
  width: 100%;
  right: 0;
  position: sticky;
  bottom: 0;
  margin-top: auto;
}

.filter_btn {
  height: 100%;
  align-items: center;
}
// .Verification_list{
//     padding: 0;
//     padding-bottom: 10px;
//     // >h6{
//     //     position: sticky;
//     //     top: 0;
//     //     background-color: var(--pro-secondary);
//     //     padding: 15px 0 10px;
//     //     margin-bottom: 0 !important;
//     // }
//     // >div{
//     //     padding-bottom: 20px;
//     // }
// }

.head {
  display: flex;
  justify-content: space-between;
}
.print_btn {
  display: flex;
}

.print {
  position: fixed;
  height: 0;
  width: 0;
  visibility: hidden;
}

.image_root{
  // border: 1px solid var(--pro-outline-border);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  img{
    object-fit: contain;
  }
}
.btns{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-left: 5px;
}
.copy_text {
  padding: 5px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.25s;
  &:hover {
    background-color: var(--pro-border-color);
  }
}