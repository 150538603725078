.multicolumn_table {
    height: 100%;
    border: 1px solid var(--pro-outline-border);
    border-radius: 3px;
    padding: 1rem;
    &:not(.pro_table_wrapper) {
      table {
        width: 100%;
        tr {
          td {
            width: 50%;
            padding: 5px 0;
            & + td {
              padding-left: 5px;
            }
          }
        }
      }
    }
    &.pro_table_wrapper {
      table th {
        white-space: initial;
      }
    }
  }
  .multicolumn_wrapper {
    --pro-gutter-y: 10px;
    ~ .multicolumn_wrapper {
      margin-top: 0px;
    }
  }
  
  .card_main_wrapper {
    height: calc(100vh - 154px);
    .card_flow_wrap {
      height: 100%;
      overflow-x: auto;
      > div {
        min-width: 210px;
      }
      // .print_list{
      //   padding-inline: 0;
      //   padding-bottom: 0;
      //   >h6{
      //     padding-inline: 11px;
      //     margin-bottom: 8px !important;
      //   }
      //   >div{
      //     height: calc(100% - 50px);
      //     overflow-y: auto;
      //     padding-inline: 11px;
      //     padding-bottom: 10px;
      //   }
      // }
    }
  }
  
  .table_main_wrapper {
    height: calc(100vh - 134px);
    position: relative;
    border-left: 1px solid var(--pro-outline-border);
    .table_flow_wrapper {
      overflow-y: auto;
      height: 100%;
      padding-right: 5px;
      padding-left: 10px;
      padding-bottom: 4.4rem;
    }
  }
  .footer_btn_wrap {
    position: absolute;
    bottom: 0;
    background-color: var(--pro-light);
    z-index: 5;
    border-top: 1px solid var(--pro-outline-border);
    width: calc(100% + 10px);
    right: -10px;
  }
  
  .filter_btn {
    height: 100%;
    align-items: center;
  }
  
  .pdf_container {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .print_btn {
    display: flex;
    justify-content: flex-end;
  }
  