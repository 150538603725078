.address {
    background-color: #f4f5f7;
    padding: 10px;
    border-radius: 5px;
    margin: 15px 0;
  }
  .button {
    margin-top: 20px;
  }

  .select-class-1 {
    width: 100px;
  }
  
  
  .offCanvas{
    --pro-offcanvas-width : 480px !important;
  }