.wrapper{
    .wrapper_child{
        &:first-child{
            width: 20%;
            color: var(--pro-invoice-label);
            font-weight: 500;
        }
    }
    & + .wrapper{
        padding-top: 10px;
        border-top: 1px solid var(--pro-outline-border);
        margin-top: 10px;
    }
    ul{
        // columns: 2;
        display: flex;
        flex-wrap: wrap;
        gap: 5px 10px;
        li{
          // position: relative;
          // padding: 4px 0;
          // padding-left: 15px;
          // &:before{
          //   position: absolute;
          //   left: 0;
          //   top: 12px;
          //   content: '';
          //   background-color: var(--pro-primary);
          //   width: 5px;
          //   height: 5px;
          //   border-radius: 50%;
          // }
        }
      }
}
