.root{
    &_list{
        background-color: var(--pro-secondary);
        padding: 0 0 1.3rem;
        max-width: 250px;
        flex: 0 0 auto;
        width: 100%;
        border-radius: 5px;
        
        >h6{
            padding-inline: 0.45rem;
            position: sticky;
            top: 0;
            background-color: var(--pro-secondary);
        }
        & + .root_list{
            margin-left: 13px;
        }
        .item_wrap {
            width: 100%;
            min-height: 102px;
            padding-inline: 0.45rem;
            // overflow-y: auto;
            // height: calc(100% - 40px);
            // scrollbar-width: thin;
            // &::-webkit-scrollbar-track{
            //     background-color: var(--pro-secondary);
            // }
            // &:not(:hover){
            //     &::-webkit-scrollbar-thumb{
            //         background-color: transparent;
            //     }
            // }
            .root_list_item{
                min-height: 157.89px;
                margin-top: 10px;
            }
        }       
    }
}
.board{
    >h6, >p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}