.address {
  background-color: #f4f5f7;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0;
}
.button {
  margin-top: 20px;
}
.add_btn {
  width: -webkit-fill-available;
  margin: 0px 0;
}
.label {
  display: flex;
  justify-content: center;
  align-items: center;
}
