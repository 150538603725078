.container_root {
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid var(--pro-outline-border);
  background-color: var(--pro-light);
  border-radius: 5px;
  align-items: center;
  button {
    white-space: nowrap;
  }
}

.report_root {
  // max-width: 170px;
  padding-right: 17px;
  margin-right: 17px;
  border-right: 1px solid var(--pro-outline-border);
  &:first-of-type h5 {
    color: var(--pro-primary-darken);
  }
  &:first-child ~ .item {
    padding-left: 0;
  }
  &:last-child {
    border-right: 0px;
    max-width: 500px;
  }
  &.big {
    max-width: initial;
  }
}


.textInner{
  width: 100%;
  max-width: 100%;
  p{
      opacity: .7;
  }
}