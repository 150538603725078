.footer_btn_wrap {
  border-top: 1px solid var(--pro-outline-border);
  background-color: var(--pro-light);
  z-index: 5;
  position: sticky;
  margin-top: auto;
  bottom: 0;
  left: 0;
}
.compare_wrap {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.tabs_wrap {
  height: 100%;
  flex: 1;
}

.modal_root {
  --pro-modal-width: 1000px;
  :global {
    .pro-pagination {
      padding-top: 10px;
      justify-content: center;
    }
  }
}
